import React from 'react';
import { createLogEntry } from "../context/logging/loggingActions";
import { LOGGING } from "../constants";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    createLogEntry(
      LOGGING.level.critical,
      `Error: ${error.toString()}\nStack: ${errorInfo.componentStack}`,
      LOGGING.category.errorBoundary
    );
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="pt-5 pl-10 pr-10 pb-5">
          <h1>Apologies - there has been a system error.</h1>
          <h2>Our team has been notified, and will fix this ASAP.</h2>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
